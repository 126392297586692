.App {
    overflow: hidden;
    height: 100vh;
    font-family: 'Comfortaa', cursive;
}

.app_main {
    
    height: 93vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-family: 'Comfortaa', cursive;
}

.title {
    font-size: 140px;
    font-family: 'Comfortaa', cursive;
    display: flex;
}

.title > div {
    color: #3968C1;
    transition: all .2s ease-in-out;
    border-radius: 35px;
}
.title > div:hover {
    background-color: #3968C1;
    color: white;
    cursor: pointer;
}


.about_link {
    font-size: 35px;
    margin-left: 42vw;
    padding: 15px;
    text-align: end;
    border-radius: 18px;
    transition: all .2s ease-in-out;
}
.about_link:hover {
    background-color: #3968C1;
    color: white;
    cursor: pointer;
}

.form {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
}

.url_in {
    border: 1px solid rgba(0, 0, 0, 0.3);
    padding: 25px;
    width: 900px;
    outline: none;
    font-size: 25px;
    border-radius: 18px;
    font-family: 'Comfortaa', cursive;
}

.form > div {
    margin: 20px 20px 10px 20px;
    font-size: 18px;
}

.submit {
    padding: 15px 50px;
    font-size: 20px;

    color: white;
    border: none;
    border-radius: 18px;
    background-color: #3968C1;
    margin-top: 20px;
    font-family: 'Comfortaa', cursive;
    transition: all .2s ease-in-out;
}
.submit:hover {
    transform: scale(1.1);
    cursor: pointer;
}
.submit:active {
    transform: scale(0.9);
}

@media screen and (max-width: 980px) {
    .title {
        font-size: 100px;
    }
    .url_in {
        width: 650px;
    }
}
@media screen and (max-width: 720px), (max-height: 680px){
    .title {
        font-size: 80px;
    }
    .url_in {
        width: 500px;
    }
}

@media screen and (max-width: 560px), (max-height: 580px){
    .title {
        font-size: 60px;
    }
    .url_in {
        width: 350px;
        font-size: 18px;
    }
    .submit {
        font-size: 15px;
    }
    .about_link {
       margin-left: 0;
    }
}
@media screen and (max-width: 430px) {
    .app_main {
        justify-content: flex-start;
        margin-top: 100px;
    }
    
    .title {
        font-size: 90px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        
    }
    .url_in {
        width: 300px;
        font-size: 18px;

    }
    .submit {
        font-size: 15px;
    }
    .about_link {
       margin-left: 0;
    }
}

