.main_about {
    height: fit-content;
}

.main2_about {
    
    height: fit-content;
    font-family: 'Comfortaa', cursive;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 150px;
}

.main2_about > h1 {
    font-weight: 400;
    font-size: 110px;
    text-align: center;
}

.under_head {
    width: 100vw;
    display: flex;
    justify-content: center;
}

.boc {
    color: white;
    background-color: #3968C1;
    padding: 20px 50px;
    font-size: 40px;
    border-radius: 20px;
    transition: all .2s ease-in-out;
}
.boc:hover {
    transform: scale(1.1);
    cursor: pointer;
}
.boc:active {
    transform: scale(0.9);
}
.boc2 {
    color: #3968C1;
    padding: 20px 50px;
    font-size: 40px;
}

.definition_nerd {
    font-size: 30px;
    width: 50vw;
    text-align: justify;
    line-height: 1.3;
    letter-spacing: 3px;
}


.main3_about {
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Comfortaa', cursive;
    flex-direction: column;

}

.definition_nonerd {
    display: flex;
    justify-content: center;

}
.definition_nonerd > img {
    width: 50%;
    height: 50%;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 30px 90px;
    border-radius: 30px;
    margin-right: 20px;
}

.definition_nonerd > p {
    width: 40%;
    height: auto;
    font-size: 30px;
    text-align: justify;
    line-height: 1.3;
    letter-spacing: 3px;
    margin-left: 20px;
}

.why {
    margin-top: 100px;
    line-height: 1.3;
    letter-spacing: 3px;
    font-size: 45px;
    text-align: center;
}
.thatswhy {
    width: 40%;
    text-align: left;
    font-size: 22px;
    text-align: justify;
    line-height: 1.3;
    letter-spacing: 3px;
    margin-left: 20px;

    
}
.thatswhy > b {
    color: #3968C1;
}
.anotherthats {
    width: 40%;
    text-align: left;
    font-size: 22px;
    text-align: justify;
    line-height: 1.3;
    letter-spacing: 3px;
    margin-left: 20px;
    margin-bottom: 100px;
}
.anotherthats > b {
    border-radius: 18px;
    color: #3968C1;
    transition: all .2s ease-in-out;
    font-size: 35px;
}
.anotherthats > b:hover {
    color: white;
    padding: 25px;
    background-color: #3968C1;
    cursor: pointer;
}

@media screen and (max-width: 1170px){
    .definition_nerd {
        width: 60vw;
    }
}
@media screen and (max-width: 1080px){
    .main2_about > h1 {
        font-size: 90px;
    }
}

@media screen and (max-width: 1500px){
    .definition_nonerd > p {
        font-size: 25px;
    }
}
@media screen and (max-width: 1400px){
    .main3_about > p {
        width: 60%;
    }
}
@media screen and (max-width: 1200px){
    .definition_nonerd > p {
        font-size: 20px;
    }
}
@media screen and (max-width: 1000px){
    .definition_nonerd  {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .definition_nonerd > p  {
       width: 70%; 
       margin: 0;
       margin-top: 50px;
       font-size: 22px;
    }

    .definition_nonerd > img  {
        width: 80%; 
        margin: 0;
    }
}


@media screen and (max-width: 900px){
    .main2_about > h1 {
        font-size: 70px;
    }
    .boc {
        font-size: 25px;
        padding: 0;
        padding: 15px 25px;
    }
    .boc2 {
        font-size: 25px;
        padding: 0;
        padding: 15px 25px;
    }
}

@media screen and (max-width: 750px){
    .main3_about > p {
        width: 80%;
    }
    .definition_nonerd > p  {
        width: 80%; 
        
     }

    .main2_about > h1 {
        font-size: 60px;
    }
    .main2_about > p {
        font-size: 30px;
        text-align: center;
    }
    .boc {
        font-size: 20px;
        padding: 0;
        padding: 12px 22px;
        border-radius: 15px;
    }
    .boc2 {
        font-size: 20px;
        padding: 0;
        padding: 12px 22px;
    }
}
@media screen and (max-width: 600px){
    .why{
        font-size: 35px;
        width: 80%;
    }
    .main2_about > p {
        font-size: 30px;
        text-align: center;
        width: 80%;
    }
    .main2_about > h1 {
        font-size: 50px;
        width: 80%;
    }
}

@media screen and (max-width: 400px){
    .under_head {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .boc {
        margin: 0;
    }
    .boc2 {
        margin: 10px 0 50px 0;
    }
    .main2_about > p {
        font-size: 22px;
        text-align: center;
        width: 80%;
    }
    .why {
        width: 80%;
        font-size: 30px;
    }
}