.res_main {
    
    height: 93vh;
    font-family: 'Comfortaa', cursive;

}
.res_form {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.res_form > div {
    font-size: 20px;
    margin: 10px;
}
.res_form > div > b {
    color: #3968C1;
}

.res_form > input {
    border: 1px solid rgba(0, 0, 0, 0.3);
    padding: 18px;
    width: 900px;
    outline: none;
    font-size: 18px;
    border-radius: 18px;
    margin: 15px;
    font-family: 'Comfortaa', cursive;
}


.res_form > button {
    padding: 18px 50px;
    font-size: 18px;

    color: white;
    border: none;
    border-radius: 18px;
    background-color: #3968C1;
    font-family: 'Comfortaa', cursive;
    transition: all .2s ease-in-out;
}
.res_form > button::before {
    content: 'skontroluj';
}
.res_form > button:hover {
    transform: scale(1.1);
    cursor: pointer;
}
.res_form > button:active {
    transform: scale(0.9);
}


.res_center_loading {
    height: 70vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.res_center_loaded {
    height: 83vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.brick {
    height: 100%;
    width: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.brick > img {
    width: 75%;
    height: auto;
}
.result {
    height: 100%;
    width: 60%;
  
    
}
.res_head {
    width: 100%;
    
    height: 35%;

    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    
}
.check_box {
    height: 150px;
    width: 150px;
    background-color: rgba(71, 160, 37, 0.6);
    border-radius: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
   

}
.check_box > svg {
    width: 80%;
    height: 80%;
}
.res_head > h1 {
    font-size: 70px;
    font-weight: 400;
    margin-left: 50px;
    
}

.res_text {
    width: 70%;
    font-size: 30px;
    text-align: justify;
    margin-top: 40px;
    line-height: 1.3;
    letter-spacing: 3px;
}
.res_text > b {
    color: #3968C1;
    cursor: pointer;
    transition: all .2s ease-in-out;
}
.res_text > b:hover {
    text-decoration: underline;
}

.res_text > a {
    color: #3968C1;
    cursor: pointer;
    transition: all .2s ease-in-out;
    text-decoration: none;
    font-weight: bold;
}
.res_text > a:hover {
    text-decoration: underline;
}



@media screen and (max-height: 650px) {
    .res_main {
        height: 88vh;
    }
   
}
@media screen and (max-width: 430px) {
    .res_main {
        height: 91vh;
    }
}

@media screen and (max-width: 1350px)
{
    .brick > img {
        width: 85%;
    }
    .res_head > h1 {
        font-size: 50px;
    }
    .res_text {
        width: 80%;
        font-size: 30px;
    }
    .res_form > div {
        display: none;
    }
}

@media screen and (max-width: 1230px){
    .res_form > button {
        margin-right: 20px;
    }
    
}
@media screen and (max-width: 1040px){
    .res_head > h1 {
        font-size: 40px;
    }
    
    .check_box {
        height: 120px;
        width: 120px;
    }
    .res_text {
        width: 80%;
        font-size: 25px;
    }
}

@media screen and (max-width: 960px){
    .res_center_loaded {
        flex-direction: column;
       
        height: fit-content;
    }
    .result {
        width: 90%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .res_head {
        justify-content: center;
        align-items: center;

    }
    .res_text {
        width: 70%;
        font-size: 25px;
        padding-bottom: 100px;
    }
    .brick > img {
        width: 350px;
        margin-bottom: 50px;
        margin-top: 50px;
    }
}

@media screen and (max-width: 700px){
    .check_box {
        height: 100px;
        width: 100px;
        border-radius: 30px;
    }
    .res_head > h1 {
        font-size: 30px;
    }
}
@media screen and (max-width: 600px){
    .res_text {
        width: 80%;
    }
}
@media screen and (max-width: 550px){
    .res_form > button::before {
      
        content: 'K';
        color: white;
    }
    .res_form > button {
        padding: 0px;
        width: 150px;
        height: 50px;
    }
    .res_head > h1 {
        text-align: center;
        margin: 0;
    }   
}

@media screen and (max-width: 450px){
   
    .check_box {
        height: 80px;
        width: 80px;
        border-radius: 25px;
    }
    .res_head{
        width: 80%;
    }
    .res_head > h1 {
        font-size: 25px;
        margin-left: 20px;
        text-align: center;
        
    }
    
}

@media screen and (max-width: 400px){
    .brick > img {
        width: 230px;
    }

    .res_form {
        flex-direction: column;
    }
    .res_form > input {
        width: 300px;
    }
    .res_form > button {
        margin: 0;
        

    }
    .res_form > button::before {
        content: "Skontroluj";
        
    }
}