@import url('https://fonts.googleapis.com/css2?family=Comfortaa&display=swap');
.bar_main {
    height: 7vh;

    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
}

.nav_btn {
    background-color: #3968C1;
    color: white;
    margin: 10px;
    padding: 10px 20px;
    border-radius: 16px;
    font-size: 18px;
    font-family: 'Comfortaa', cursive;
    transition: all .2s ease-in-out;
}

.nav_btn:hover {
    transform: scale(1.1);
    cursor: pointer;
}
.nav_btn:active {
    transform: scale(0.9);
}

@media screen and (max-width: 430px) {
    .bar_main {
        height: 9vh;
    }
    .nav_btn {
        padding: 13px 25px;
    }

}

@media screen and (max-height: 750px) {
    .bar_main {
        height: 9vh;
    }
    .nav_btn {
        padding: 13px 25px;
    }
}
@media screen and (max-height: 650px) {
    .bar_main {
        height: 12vh;
    }
    .nav_btn {
        padding: 13px 25px;
    }
}